@import url(https://fonts.googleapis.com/css2?family=Syne:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#appbar .icon{width:36px;height:36px;color:#eee;margin:1rem}

#about-text{color:#eee;line-height:2rem}#about-text h1,#about-text h2,#about-text h3,#about-text h4,#about-text h5,#about-text h6{margin:1rem 0}

.project{border:0;background:rgba(255,255,255,0.75);box-shadow:4px 4px 4px rgba(0,0,0,0.25);margin:2rem}.project a{color:#111;-webkit-text-decoration:underline 2px dotted;text-decoration:underline 2px dotted}.project .project-img{width:360px;height:240px;border:1px solid lightgrey}

.App {
  font-family: "Syne", sans-serif;
  text-align: center;
  background-image: linear-gradient(
    to right top,
    #212b39,
    #02376c,
    #003e9f,
    #433bcb,
    #8c12eb
  );
}

.section {
  height: 100vh;
}

.section-header {
  color: #eee;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

