#about-text {
  color: #eee;
  line-height: 2rem;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1rem 0;
  }
}
