@import url("https://fonts.googleapis.com/css2?family=Syne:wght@500&display=swap");

.App {
  font-family: "Syne", sans-serif;
  text-align: center;
  background-image: linear-gradient(
    to right top,
    #212b39,
    #02376c,
    #003e9f,
    #433bcb,
    #8c12eb
  );
}

.section {
  height: 100vh;
}

.section-header {
  color: #eee;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
