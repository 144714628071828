.project {
  border: 0;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 2rem;
  a {
    color: #111;
    text-decoration: underline 2px dotted;
    // background-color: #fdc228e0;
  }
  .project-img {
    width: 360px;
    height: 240px;
    border: 1px solid lightgrey;
  }
}
